<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item>Blog</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Blog:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Blog Title"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <AddBlogDialog @re-fresh="getBlog" />
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="blog"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getBlog"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Blog Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Blog</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Image"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="75px"
                >
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.blogImage"
                      style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                    >
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Title"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.blogTitle }} </span><br />
                    <small>Add On: {{ formattedDate(scope.row.createdAt) }} </small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Status"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="100px"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex;">
                      <el-tooltip
                        :content="scope.row.isActive ? 'Active' : 'Deactivated'"
                        placement="top"
                      >
                        <el-switch
                          v-model="scope.row.isActive"
                          @change="switchStatus(scope.row.isActive, scope.row.blogID)"
                          active-color="#13ce66"
                          inactive-color=""
                        >
                        </el-switch>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="warning"
                           :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                          @click="showEditblogData(scope.row)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                           :disabled="$store.state.userRoles !== 'SUPER_ADMIN'"
                          @click="deleteBanner(scope.row.blogID)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      <EditBlogDialog
        :showBlogEditDialog="showBlogEditDialog"
        :blogData="blog_Data"
        @closeEditBlogDialog="closeEditBlogDialog"
        @re-fresh="getBlog"
      />
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import AddBlogDialog from "../../components/backoffice/dialogs/add-blog-dialog.vue";
  import EditBlogDialog from "../../components/backoffice/dialogs/edit-blog-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      AddBlogDialog,
      EditBlogDialog,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showBlogEditDialog: false,
        blog: [],
        blog_Data: {},
        decoded_token: {},
        decoded_token2: {},
        first_name: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "blogTitle",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getBlog();
    },
  
    methods: {
      showEditblogData(blogData) {
        this.showBlogEditDialog = true;
        this.blog_Data = blogData;
      },
  
      closeEditBlogDialog() {
        this.showBlogEditDialog = false;
      },
  
      async getBlog() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`blog`);
          if (request.data.success && request.data.message === "BLOG_FETCHED_SUCCESSFULLY") {
            this.blog = request.data.posts;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch blog Posts now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteBanner(blogID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Post. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`blog/${blogID}`);
              if (
                request.data.success &&
                request.data.message === "BLOG_DELETED_SUCCESSFULLY"
              ) {
                this.getBlog();
                this.$notify({
                  title: "Success",
                  message: "Post deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Post, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
  
  async switchStatus(activation_status, blogID) {
    try {
      this.is_changing = true;
      let request = await this.$http.patch(
        `blog/change_isActive_status/${blogID}`, {
          isActive: activation_status,
        }
      );
      if (request.data.success) {
        this.getBlog();
        this.showSuccessMessage("Success", );
            return this.$notify({
              title: "Success",
              message: "Banner status changed",
              type: "success",
            });
      } else {
        throw "UNEXPECTED_RESPONSE";
      }
    } catch (error) {
      if (error.message == "Network Error") {
        this.is_changing = false;
        return this.showFailedMessage(
          "Connection failed",
          "A network error occurred, please try again."
        );
      }
      this.loadingError = true;
      this.showFailedMessage(
        "Unable to change banner status",
        "An unexpected error occurred, please try again"
      );
    } finally {
      this.is_changing = false;
    }
  },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>